<template>
    <div>
        <div class="div-employees content-wrapper">
            <TitlePage 
                :title="titlePage" 
                :iconClass="iconClass" 
                :routeButton="$route.params.rd_billing ? 'companies_rd_billing' :'companies'"
            />

            <div class="table-head">
                <span class="show-total-employees">Usuários Encontrados: <b>{{employees.length}}</b></span>
                <span class="show-total-employees">Usuários Engajados: <b>{{employees.filter(employee =>
                employee.isEngaged).length}}</b></span>
                <span class="show-total-employees">Usuários Ativos: <b>{{employees.filter(employee => employee.inactive
                === undefined || !employee.inactive).length}}</b></span>
                <input type="text" class="input-search-employees" v-model="searchEmployees"
                    @keyup="getFilterEmployees()" placeholder="Pesquisar por email, nome, tipo">
                <i id="sear-employee-glass" class="fa fa-search" @click="getFilterEmployees()"></i>
            </div>
            <div class="modal fade" id="modalDisabledInativity">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="modalDisabledInativity">
                                Deseja {{checkPropetieIsTrue('disableInactivity') ? 'ativar' : 'desativar'}}
                                o periodo de inatividade para <b>{{selectedEmployee.name}}</b> na tela de Monitoramento?
                            </h5>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-outline-secondary btn-sm"
                                data-bs-dismiss="modal">Voltar</button>
                            <button @click="changeDisableInactivity()" type="button"
                                class="btn btn-outline-danger btn-sm"
                                data-bs-dismiss="modal">{{checkPropetieIsTrue('disableInactivity') ? 'Ativar' :
                                'Desativar'}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="modalRedefinePassword">
                <div class="modal-dialog">
                    <div class="modal-content" v-if="!handleShowConfirmRedefinePassword">
                        <div class="modal-header">
                            <h5 class="modal-title" id="modalDisabledInativity">
                                Você deseja realmente alterar a senha do usuário <b>{{selectedEmployee.name}}</b>?
                            </h5>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-outline-secondary btn-sm"
                                data-bs-dismiss="modal">Voltar</button>
                            <button @click.prevent="handleRedefinePassword(selectedEmployee)" type="button"
                                class="btn btn-outline-success btn-sm">Redefinir</button>
                        </div>
                    </div>
                    <div class="modal-content" v-else-if="loadingRedefine">
                        <img src="../../../../assets/images/loading.svg" alt="loading" width="200" style="margin: auto">
                    </div>
                    <div class="modal-content" v-else>
                        <div class="modal-header d-flex flex-column">
                            <h5 class="modal-title">
                                Nova senha do usuário: <b>{{selectedEmployee.name}}</b>
                            </h5>
                            <h5 class="modal-title">
                                Senha: <b>{{redefinePassword}}</b>
                            </h5>
                        </div>
                        <div class="modal-footer">
                            <button type="button"
                                @click="handleShowConfirmRedefinePassword = !handleShowConfirmRedefinePassword"
                                class="btn btn-outline-secondary btn-sm" data-bs-dismiss="modal">Fechar</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="modalDisabledEmployee">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="modalDisabledEmployee">
                                Deseja {{checkPropetieIsTrue('inactive') ? 'Ativar' : 'Inativar'}}
                                o funcionário {{selectedEmployee.name}}?
                            </h5>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-outline-secondary btn-sm"
                                data-bs-dismiss="modal">Voltar</button>
                            <button @click="changeStatusEmployeeAccess()" type="button"
                                class="btn btn-outline-danger btn-sm"
                                data-bs-dismiss="modal">{{checkPropetieIsTrue('inactive') ? 'Ativar' :
                                'Desativar'}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="loading">
                carregando usuarios...
            </div>
            <div v-else class="card-body table-responsive p-0 max-height70vh">
                <table class="table table-hover table-stripped table-head-fixed">
                    <thead>
                        <tr>
                            <th class="text-align-center">ID</th>
                            <th class="text-align-center">Nome</th>
                            <th class="text-align-center">Email</th>
                            <th class="text-align-center">Nps</th>
                            <th class="text-align-center">Registro em</th>
                            <th class="text-align-center">Tipo/Status</th>
                            <th class="text-align-center">Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(employee, i) in employees" :key="i">
                            <td style="text-align:center">{{employee._id}}</td>
                            <td style="text-align:center">
                                <div class="d-flex justify-content-start">
                                    <span
                                        class="mr-2 span-engaged-disengaged d-flex justify-content-center"
                                        :class="employee.isEngaged ? 'engaged' : 'disengaged'"
                                        :title="employee.isEngaged ? 'Usuário engajada' : 'Usuário desengajada'">
                                        <i :class="employee.isEngaged ? 'fa fa-thumbs-up' : 'fa fa-thumbs-down'"></i>
                                    </span>
                                    {{employee.name}}
                                </div>
                            </td>
                            <td style="text-align: center">
                                <p class="m-0 p-0">{{employee.email}}</p>
                            </td>
                            <td class="text-align-center">{{employee.last_vote_nps || employee.last_vote_nps === 0 ?
                            employee.last_vote_nps : 'S/A'}}</td>
                            <td class="text-align-center">{{formatDataEmployee(employee.created_at, 'DD/MM/YYYY')}}</td>
                            <td class="">
                                <span class="badge pl-1 pr-1 mr-1" :class="{'bg-dark': employee.role === 'admin', 'bg-info': employee.role !== 'admin'}">
                                    {{employee.role}}
                                </span>
                                <span class="badge pl-1 pr-1" :class="{ 'bg-success': employee.inactive === undefined || !employee.inactive, 'bg-danger': employee.inactive }">
                                    {{ employee.inactive === undefined || !employee.inactive ? 'Ativo' : 'Inativo' }}
                                </span>
                            </td>
                            <td class="d-flex justify-content-center">
                                <div class="btn-group">
                                    <button type="button" class="btn" data-bs-toggle="dropdown"
                                        data-bs-target="#dropdown">
                                        <i class="fas fa-ellipsis-v text-secondary"></i>
                                    </button>
                                    <div id="dropdown" class="pl-2 pr-2 dropdown-menu dropdown-menu-left">
                                        <div class="d-flex justify-content-between">
                                            <button @click="handleSelectEmployee(employee)" data-bs-toggle="modal"
                                                data-bs-target="#modalDisabledEmployee"
                                                :title="`${employee.inactive ? 'Ativar' : 'Inativar'} Funcionário`"
                                                :class="`btn btn-sm ${employee.inactive ? 'btn-outline-warning' : 'btn-outline-success'}`">
                                                <i class="fa fa-power-off"></i>
                                            </button>
                                            <button @click="handleSelectEmployee(employee)" data-bs-toggle="modal"
                                                data-bs-target="#modalDisabledInativity" data-bs-whatever="@fat"
                                                title="Desabilitar Tempo de Inatividade"
                                                :class="`btn btn-sm ${employee.disableInactivity ? 'btn-outline-warning' : 'btn-outline-success'}`">
                                                <i class="fa fa-clock"></i>
                                            </button>
                                            <button @click="handleSelectEmployee(employee)" data-bs-toggle="modal"
                                                data-bs-target="#modalRedefinePassword" title="Alteração de senha"
                                                class="btn btn-sm btn-outline-warning">
                                                <i class="fa fa-unlock"></i>
                                            </button>
                                            <a href="https://app.tallos.com.br/#/" target="_blank"
                                                @click="onCopy($event, employee._id)" style="cursor: pointer"
                                                title="Ir pro tallos chat" class="btn btn-outline-primary btn-sm">
                                                <i class="fa fa-share"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
// ### import libs ###
import moment from 'moment-timezone'
import "moment/locale/pt-br.js"

// ### import components ###
import TitlePage from '../../../shared/components/title-page/TitlePage.vue'

export default {
    components: {
        TitlePage
    },

    data() {
        return {
            titlePage: `Usuários - ${this.$route.params.company_name}`,
            iconClass: 'fa fa-users',
            loading: true,
            employees: [],
            employeesGroup: [],
            searchEmployees: '',
            emailCopy: '',
            selectedEmployee: {},
            newModalGeneralSelect: true,
            handleShowConfirmRedefinePassword: false,
            redefinePassword: '',
            loadingRedefine: false,
        }
    },

    methods: {
        handleRedefinePassword({ _id }) {
            this.handleShowConfirmRedefinePassword = true
            this.loadingRedefine = true
            this.$tallos_admin_api.put(`employees/redefine/password/${_id}`)
                .then(response => {
                    this.redefinePassword = response.data.password
                    this.loadingRedefine = false
                })
                .catch(err => console.error(err))
        },
        
        checkPropetieIsTrue(prop_name) {
            return this.selectedEmployee[prop_name];
        },

        getEmployees() {
            let self = this
            self.$tallos_admin_api.defaults.headers.common['Authorization'] = `Bearer ${self.$store.getters.tokenJWT}`

            this.$tallos_admin_api.get(`employees/${self.$route.params.company_id}`)
                .then(res => {
                    self.employees = res.data
                    self.employeesGroup = res.data
                    self.loading = false
                })
                .catch(e => {
                    this.$toasted.global.defaultError({ msg: 'Erro ao buscar empregados.' })
                })
        },

        formatDataEmployee(data, format) {
            return moment(data).format(format)
        },

        getFilterEmployees() {

            let self = this

            if (self.searchEmployees == '') {
                self.employees = self.employeesGroup
            } else {
                self.employees = self.employeesGroup.filter(employee => {
                    return (employee.name.match(new RegExp(self.searchEmployees, "i"))
                        || employee.email.match(new RegExp(self.searchEmployees, "i"))
                        || employee.role.match(new RegExp(self.searchEmployees, "i"))
                    )
                })
            }

        },

        hasCopyEmail(employee) {
            return employee.email == this.emailCopy ? true : false
        },

        onCopy(event, id) {

            let baseHtmlId = `pre-${id}`
            let copyText = document.getElementById(baseHtmlId)

            self.emailCopy = copyText.value
            this.$toasted.global.default({ msg: `<b> ${self.emailCopy}&nbsp&nbsp&nbsp copiado!</b>` })

            /* Select the text field */
            copyText.select();
            copyText.setSelectionRange(0, 99999); /*For mobile devices*/

            document.execCommand("copy");

            // window.open('https://app.tallos.com.br/#/', "width=200,height=100");
        },

        updateReviewEmployee(employee) {
            let self = this

            let newModalGeneral = !employee.modalGeneral

            self.$tallos_admin_api.defaults.headers.common['Authorization'] = `Bearer ${self.$store.getters.tokenJWT}`

            this.$tallos_admin_api.put(`employees/update-employee-modal-general/${employee._id}`, {
                newModalGeneral
            })
                .then(res => {
                    this.$toasted.global.defaultSuccess({ msg: 'Operador atualizado!' })
                    this.getEmployees()
                })
                .catch(e => {
                    this.$toasted.global.defaultError({ msg: 'Erro ao atualizar avaliação do operador.' })
                })
        },

        updateReviewEmployeeForCompany() {

            let self = this

            self.$tallos_admin_api.defaults.headers.common['Authorization'] = `Bearer ${self.$store.getters.tokenJWT}`

            this.$tallos_admin_api.put(`employees/update-employee-modal-general-for-company/${self.$route.params.company_id}`, {
                newModalGeneral: self.newModalGeneralSelect
            })
                .then(res => {
                    this.$toasted.global.defaultSuccess({ msg: 'Operadores atualizados !' })
                    this.getEmployees()
                })
                .catch(e => {
                    this.$toasted.global.defaultError({ msg: 'Erro ao atualizar avaliação do operador.' })
                })

        },

        changeDisableInactivity() {
            this.$tallos_admin_api.put('employees/change-inactivity', {
                _id: this.selectedEmployee._id,
                disableInactivity: this.selectedEmployee.disableInactivity
            })
                .then(res => {
                    this.$toasted.global.defaultSuccess({ msg: `Inatividade ${this.checkPropetieIsTrue('disableInactivity') ? 'desativada' : 'ativada'}.` })
                    this.getEmployees();
                })
                .catch(e => {
                    this.$toasted.global.defaultError({ msg: `Erro ao ${this.checkPropetieIsTrue('disableInactivity') ? 'desativar' : 'ativar'}.` })
                })
        },

        changeStatusEmployeeAccess() {
            this.$tallos_admin_api.put('employees/change-employee-access', {
                _id: this.selectedEmployee._id,
                inactive: this.selectedEmployee.inactive
            })
                .then(res => {
                    this.$toasted.global.defaultSuccess({ msg: `Funcionário ${this.checkPropetieIsTrue('inactive') ? 'ativado' : 'desativado'}.` })
                    this.getEmployees();
                })
                .catch(e => {
                    this.$toasted.global.defaultError({ msg: `Erro ao ${this.checkPropetieIsTrue('inactive') ? 'ativado' : 'desativado'}.` })
                })
        },

        handleSelectEmployee(employee) {
            this.selectedEmployee = employee;
        }
    },

    mounted() {
        let self = this
        document.title = 'Tallos Admin - Usuários/Empresa'

        self.getEmployees()
    }
}
</script>

<style scoped>
.div-employees {
    background-color: rgba(247, 247, 247, 0.0);
}

.div-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px 10px 30px;

}

.card-body {
    width: 95%;
    max-height: 60vh;
    display: flex;
    justify-content: center;
    margin: 0px auto;
    padding: 0px 10px;
    border: 1px solid #0001;
}

table {
    border: 0.1px solid #0001;
}

table thead th {
    top: -3px !important;
}

table thead th:first-child {
    width: 100px !important;
    padding: 10px 0px !important;
}

.table-head {
    width: 95%;
    margin: 10px auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
}

.input-search-employees {
    border: 1px solid #0002;
    padding: 5px 10px;
    border-radius: 5px;
    width: 30%;
    font-size: 16px
}

#sear-employee-glass {
    position: absolute;
    right: 10px;
    top: 11px;
    color: #0005;
    cursor: pointer;
}

.show-total-employees {
    font-size: 15px;
    font-weight: 500;
    color: rgba(0, 0, 0, .7);
}

.textarea-copy {
    border: none;
    text-align: center;
    padding: 0px;
    height: 28px;
    margin-top: 0px;
    margin-bottom: 0px;
    resize: none;
    min-width: 350px;
}

.textarea-copy {
    background-color: inherit;
}

.textarea-copy:focus {
    outline: none;
}

.select-review-operator {
    width: 15px !important;
    color: #0009;
    background: #FFF;
    border: none;
    outline: none;
}

.span-engaged-disengaged {
    display: flex;
    justify-content: center;
    align-items: center;
}

.disengaged {
    font-weight: 600;
    font-size: 14px;
    color: rgb(245, 81, 31);
}

.text-align-center {
    text-align: center;
}

.max-height70vh {
    max-height: 70vh
}
</style>
